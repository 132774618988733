import Vuex from 'vuex'
import user from './user.js'
import subscription from './subscription.js'
import group from './group.js'
import event from './event.js'
import messenger from './messenger.js'
import report from './report.js'
import option from './option.js'
import discussion from './discussion.js'
import album from './album.js'
import deal from './deals.js'
import merchant from './merchants.js'
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })],
    state: {
        authStatus: '',
    },
    mutations: {
        // TODO
    },
    actions: {
        // TODO
    },
    getters: {
        authorized: state => !!localStorage.getItem('token'),
        authstatus: state => state.authStatus,
    },
    modules: {
        user,
        subscription,
        group,
        event,
        messenger,
        report,
        option,
        discussion,
        album,
        deal,
        merchant
    }
})
