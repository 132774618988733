<template>
  <Preloader :is-loading="isLoading"></Preloader>
  <div class="card p-0 mt-5">
    <div class="table-responsive">
      <table class="table table-items">
        <thead>
          <tr>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">
                <span class="ml-3">NAME</span>
              </h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">EMAIL ADDRESS</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">TIER</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">PHONE NUMBER</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">STATUS</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-center">MEMBERSHIPS</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-center">TAKE ACTION</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-center">DATE STARTED</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-center">DATE EXPIRATION</h1>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in userList" :key="row.in" class="fs-14">
            <td>
              <div class="icheck-primary d-inline">
                  <img
                    class="rounded mr-3 tbl-img"
                    :src="row.primary_photo?.sizes?.thumbnail"
                    @error="imageUrlAlt"
                  />
                  {{ row.first_name }} {{ row.last_name }}
              </div>
            </td>
            <td>{{ row.email }}</td>
            <td>{{ row.subscription_type }}</td>
            <td>{{ row.mobile_number }}</td>
            <td>
              {{
                row.deleted_at !== null
                  ? "Deleted"
                  : row.is_suspended && row.deleted_at === null
                  ? "Suspended"
                  : row.status === 4 && row.deleted_at === null
                  ? "Flagged"
                  : row.status === 3 && row.deleted_at === null
                  ? "Deactivated"
                  : row.status === 0 && row.deleted_at === null
                  ? "Unverified"
                   : row.status === 1 && row.deleted_at === null
                  ? "Verified"
                  : "Active"
              }}
            </td>
            <td class="dropdown text-center">
              <a class="nav-link" data-toggle="dropdown" href="#">
                View Memberships
              </a>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                <router-link
                  v-for="(type, i) in memberships"
                  :key="i"
                  :to="`/user-${type.toLowerCase()}/${row.user_name}`"
                  class="dropdown-item p-2"
                >
                  {{ type }}
                </router-link>
              </div>
            </td>
            <td class="text-center">
              <ce-button type="button" class="btn btn-nero btn-flat btn-sm">Cancel Subscription</ce-button>
            </td>
            <td class="text-center">{{ moment(row.active_subscriptions[0]?.created_at).format('ll') }}</td>
            <td class="text-center">{{ moment(row.active_subscriptions[0]?.ends_at).format('ll') }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination-component
      class="mt-3 mb-2 mr-5"
      :total-pages="totalPage"
      :per-page="25"
      :current-page="currentPage"
      @pagechanged="onPageChange"
    />
  </div>

</template>
<script>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { userEnums} from "@/composable/enums.js";
import ProfileImageComponent from "../../components/ProfileImageComponent.vue";
import UserInformationComponent from "../../Pages/User/UserInformationModal.vue";
import Preloader from "../../Layouts/Common/Preloader.vue";
import PaginationComponent from "../PaginationComponent.vue";
export default {
  name: "App",
  components: {
    ProfileImageComponent,
    UserInformationComponent,
    Preloader,
    PaginationComponent,
  },
  props: {
    isLoad: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ["done"],
  setup(props, { emit }) {
    const store = useStore();
    const userList = ref([]);
    const { memberships } = userEnums();
    const isLoading = ref(false);
    const currentPage = ref(1);
    const totalPage = ref(null);

    const imageUrlAlt = (event) => {
      event.target.src = "//via.placeholder.com/150";
    };

    const onPageChange = (page) => {
      currentPage.value = page;
    };

    const loadSubscibers = async () => {
      isLoading.value = true;
      let response = await store.dispatch("loadSubscribersList", {
        type: event,
        perPage: 25,
        page: currentPage.value,
      });
      userList.value = response.data;
      totalPage.value = response.meta.last_page;
      isLoading.value = false;
    }

    onMounted( async () => {
      loadSubscibers();
    })

    watch(
      () => currentPage.value,
      () => loadSubscibers()
    );

    watch(
      () => props.isLoad,
      () => loadSubscibers()
    );



    return {
      userList,
      memberships,
      imageUrlAlt,
      isLoading,
      currentPage,
      onPageChange,
      totalPage,
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style scoped>
.color-gray {
  color: #727e8b;
}
.form-control:focus {
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}
.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}
.top-70 {
  top: 0% !important;
}
.start-58 {
  left: 58% !important;
}
.card {
  border: 0;
}
.widget-user-header {
  padding: 0;
  height: auto !important;
}
.widget-user .widget-user-header {
  padding: 0px;
}
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.link-name {
  color: rgb(85, 85, 194);
}
.link-name:hover {
  cursor: pointer;
}
.fa {
  color: black;
}
.fas:hover {
  cursor: pointer;
}
.fs-14 {
  font-size: 14px !important;
}

.table-responsive {
  margin-top: -35px;
}
.tbl-ll-date {
  color: #6c757d;
  position: relative;
  left: 25px;
  float: left;
  margin-top: -5px;
}
.tbl-img {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.profile-img {
  object-fit: contain;
  border-radius: 0.25em 0.25em 0em 0px;
  width: 100%;
}
.table-items th {
  padding-bottom: 2px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.table-items tbody,
tr,
td {
  white-space: nowrap !important;
}
.background-gold {
  background: gold;
}
.txt-color-gray {
  color: #6c757d;
}
.fa-circle-plus,
.fa-pen-to-square,
.fa-magnifying-glass-plus:hover {
  cursor: pointer;
}
</style>
