import axios from 'axios'

const setupHeaderConfig = (config) => {
    // -------------------------------------------------------------
    // Note: This will be uncommented once I finish the auth state / store
    // -------------------------------------------------------------
    // const token
    const token = localStorage.getItem('token')
        // !config.headers ? config.headers = {} : null\
    if (config.headers === undefined) {
        config.headers.Authorization = 'Bearer ' + token
    } else {
        config.headers.Authorization = 'Bearer ' + token
    }

    return config
}

export default ({
    state: {
        merchantList: [],
        merchantStatistics: [{
            icon: 'user',
            labels: 'Merchants',
            data: 0,
            link: '/manage-merchants',
            heading: [],
            // menu1: [{
            //         label: 'All',
            //         link: '/manage-users?type=reset-password'
            //     },
            //     {
            //         label: 'Featured',
            //         link: '/manage-users?type=update-profile'
            //     }
            // ],
            // menu2: []
        }],
    },
    mutations: {
        loadMerchantStatistics(state, payload) {
            state.merchantStatistics[0].heading = payload.statistics
            state.merchantStatistics[0].data = payload.all
        },
        loadMerchantLists(state, payload) {
            state.merchantList = payload
        },
    },
    getters: {},
    actions: {
        async loadMerchantStatistics({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/merchants/statistics', newConfig).then((response) => {
                commit('loadMerchantStatistics', response.data.data)
            })
        },
        async loadMerchantList({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/merchants', newConfig).then((response) => {
                commit('loadMerchantLists', response.data.data)
                return response.data
            })
        },
        async setMerchantAsFeatured({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config)
            return await axios.post(`admin-panel/merchants/${params.id}/set-featured`, params , newConfig).then((response) => {
                return response.data
            })
        },
        async setMerchantShowOnResult({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config)
            return await axios.post(`admin-panel/merchants/${params.id}/set-show-on-result`, params , newConfig).then((response) => {
                return response.data
            })
        },
    },
});
